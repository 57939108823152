.about-us {
  .about-us-container {
    position: relative;
    z-index: 1;
    text-align: left;
    .about-section {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin-bottom: 40px;
      .about-section-img {
        // background-image: url("../../assets/img/hillcrest-estates-map-1.png");
        // background-size: cover;
        width: 400px;
        height: 346px;
        border-radius: 16px;
      }
      .about-us-section-content {
        margin-top: 40px;
        width: 100%;
        > h4 {
          font-weight: bold;
          font-size: 1.2em;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .about-us {
    .about-us-container {
      position: relative;
      z-index: 1;
      text-align: left;
      .about-section {
        flex-wrap: wrap;
      }
    }
  }
}
