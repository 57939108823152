.announcement-selected {
  position: relative;
  z-index: 1;
  text-align: left;
  display: flex;
  gap: 15px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: capitalize;

  .edit {
    color: #598B54;
    margin-left: 5px;
    cursor: pointer;
  }

  .delete {
    color: #CB5959;
    cursor: pointer;

    >svg {
      margin-right: 5px;
    }

    &.confirm {
      padding: 10px;
      background-color: #CB5959;
      color: white;
      border-radius: 50px;
    }
  }
}