.search-address {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1em;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  z-index: 1;

  > label {
    text-transform: capitalize;
  }
  .searchbar-addresses {
    position: relative;
    .search-bar {
      // position: relative;
      display: flex;
      position: relative;
      background: white;
      margin-top: 10px;
      color: black;
      border-radius: 100px;
      padding: 0.5rem;
      border: 1px solid #cccccc;

      input {
        border: none;
        outline: none;
        width: 100%;
        margin-left: 20px;
        font-size: 1em;

        ::placeholder {
          opacity: 0.3;
        }
      }
      .circle {
        padding: 10px;
        background: #372828;
        // position: absolute;
        border-radius: 50%;
        cursor: pointer;
        // top: calc(50% - 30px/2);
        // right: 15px;

        svg {
          color: white;
          height: 15px;
        }
      }
    }
    .addresses {
      background-color: white;
      color: black;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border-top: 1px solid black;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      padding-bottom: 25px;
      max-height: 200px;
      border: 1px solid #cccccc;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        display: none;
      }
      ul {
        list-style: none;
        li {
          padding: 10px 25px;
          cursor: pointer;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
        }
        li:hover {
          background-color: rgba($color: gray, $alpha: 0.3);
        }
      }
    }
    .addresses::-webkit-scrollbar {
      display: none;
    }
    .open {
      border-radius: 25px 25px 0 0;
      // border-top-right-radius: 25px;
      // border-top-left-radius: 25px;
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
    }
  }
}
