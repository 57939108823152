@import "./global/typography.css";
@import "./global/base.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &:disabled{
    opacity: .8;
    cursor: not-allowed !important;
  }
}
body {
  font-family: "Source Sans Pro", sans-serif;
  color: white;
  font-size: 16px;
}
