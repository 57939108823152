.table-subcolumn {
  position: relative;
  input[type="radio"] {
    display: none;
    &:checked + label .radio-button {
      background-color: #598b54;
      color: white;
    }
  }
  // label {
  //   display: inline-block;
  //   vertical-align: middle;
  //   cursor: pointer;
  // }
  > label {
    width: 100%;
    height: 100%;
    .radio-button {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-right: 10px;
      color: #598b54;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      &:checked {
        background-color: #3cba54;
      }
    }
  }
}
