.create-ad {
  h1 {
    margin: 0;
    text-align: left;
  }
  form {
    margin-top: 20px;

    .bulletin-message {
      height: 200px;
      resize: none;
      outline: none;
      border-radius: 15px;
      padding: 15px;

      font-family: inherit;
      font-size: inherit;
    }
    .choose-image {
      display: flex;
      align-items: center;
      .bulletin-image {
        display: none;
      }
      .select-button {
        background-color: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: none;
        padding: 10px 20px;
        margin-right: 10px;
        font-weight: bold;
        cursor: pointer;
        > label {
          cursor: pointer;
        }
      }
      .image-preview {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .primary-button {
      margin-top: 20px;
    }
  }
}