.input-item {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1em;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  > label {
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .error {
    color: #cb5959;
    margin-left: 15px;
  }
  .input-wrapper {
    display: flex;
    position: relative;
    background: white;
    color: black;
    border-radius: 50px;
    overflow: hidden;
    padding: 0.5rem;
    border: 1px solid #372828;
    input {
      border: none;
      outline: none;
      width: 100%;
      margin-left: 20px;
      font-size: 1em;
      height: 38.5px;

      ::placeholder {
        opacity: 0.3;
      }
    }
    .circle {
      padding: 10px;
      background: #372828;
      // position: absolute;
      border-radius: 50%;
      cursor: pointer;
      // top: calc(50% - 30px/2);
      // right: 15px;

      svg {
        color: white;
        height: 15px;
      }
    }
  }
}
