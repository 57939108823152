.file-input {
  display: flex;
  align-items: center;
  .bulletin-image {
    display: none;
  }
  .select-button {
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
    > label {
      cursor: pointer;
    }
  }
  .image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    .image-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
