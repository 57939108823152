.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 2rem 10rem;
  z-index: 1;

  .logo {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    strong {
      font-family: "Abril Fatface";
      font-size: 23px;
      font-size: 1.5rem;
    }
    img {
      margin-right: 10px;
    }
  }

  .auth {
    width: 250px;
    display: flex;
    a {
      color: white;
      text-decoration: none;
      width: 100%;
      font-weight: 600;
    }
    a:hover {
      opacity: 0.5;
    }
  }
}


@media only screen and (max-width: 768px) {
.header{
  padding: 10px;
}
}