.log-in {
  background-image: url(../../assets/img/oldHouse2.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  text-align: left;
  .content {
    margin: 0 auto;
    min-width: 500px;

    .label {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 1.5em;
      text-transform: uppercase;
    }
    h1 {
      font-size: 4em;
      font-family: "Abril Faface";
      line-height: 0.7;
      text-transform: capitalize;
    }
    > form {
      display: grid;
      gap: 15px;
      margin: 20px 0;
      .input-check {
        display: flex;
        margin-bottom: 30px;
        p {
          margin-left: 10px;
        }
      }
      .primary-button {
        color: white;
        background-color: #372828;
        font-size: 1.2em;
        padding: 1em 3.5em;
        border: none;
        border-radius: 7em;
        cursor: pointer;
        text-transform: uppercase;
        margin: 0 auto;
      }
    }
    .login-paragraph {
      text-align: center;
      margin: 10px 0;
      a {
        color: white;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .log-in {

    .content {
      min-width: 100%;
      padding: 10px;
    }
  }
}