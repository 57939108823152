.announcements {
  .announcements-container {
    // padding: 35px;
    text-align: left;

    .announcement-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      gap: 15px;
      align-items: center;
    }
    .no-more {
      text-align: center;
      margin: 5em auto;
      font-weight: bold;
    }
    // height: 200vh;
    @media only screen and (max-width: 768px) {
      .survey-answers {
        flex-direction: column;
        row-gap: var(--space-sm);
      }
    }
  }
}
