.logo {
  display: flex;
  align-items: center;
  strong {
    white-space: nowrap;
    font-size: 25px;
    margin-left: 5px;
    font-family: "Abril Fatface";
  }
}
