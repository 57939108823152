.radio-input {
  input[type="radio"]:checked {
    background-color: green;
    border-color: green;
  }
  > label {
    margin-right: 10px;
    input[type="radio"] {
      margin-right: 3px;
      &:checked {
        background-color: #598b54;
        border-color: #598b54;
      }
    }
  }
}
