.card {
  position: relative;
  z-index: 1;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  width: 100%;
  // max-width: 350px;
  height: 250px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-family: "Source Sans Pro";

  // .card-image {
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   height: 100%;
  //   width: 100%;
  //   overflow: hidden;
  //   img {
  //     max-width: 100%;
  //     height: auto;
  //   }
  // }
  // .card-content {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   padding: 15px;
  //   height: 100%;
  //   .title-description {
  //     text-align: left;

  //     .title {
  //       font-size: 1.3em;
  //       margin-bottom: 10px;
  //     }
  //     .description {
  //       display: -webkit-box;
  //       -webkit-box-orient: vertical;
  //       -webkit-line-clamp: 4;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       max-width: 100%;
  //     }
  //   }
  //   .details {
  //     display: flex;
  //     align-items: center;
  //     .info {
  //       text-align: left;
  //       margin-left: 10px;
  //     }
  //   }
  // }
  .details-button {
    position: absolute;
    right: 20px;
    bottom: 35px;
    font-size: 1em;
    text-transform: uppercase;
    color: #598b54;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
  }
}
