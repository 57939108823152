.action-page {
  position: fixed;
  top: 0;
  right: -560px;
  width: 560px;
  height: 100vh;
  background-color: white;
  z-index: 2;
  box-shadow: -14px 4px 16px rgba(0, 0, 0, 0.14);
  padding: 40px;
  transition: 0.3s;
  overflow-y: scroll;
  text-align: left;
  &.active {
    right: 0;
  }
  .action-page-header {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    .close {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      color: #598b54;
      svg {
        margin-left: 5px;
        font-size: 1.2em;
      }
    }
    .bulletin-action-buttons {
      display: flex;
      gap: 15px;
      align-items: center;
      .delete {
        color: #cb5959;
        cursor: pointer;

        > svg {
          margin-right: 5px;
        }

        &.confirm {
          padding: 5px 10px;
          background-color: #cb5959;
          color: white;
          border-radius: 50px;
        }
      }
    }
  }
  .action-page-content {
    padding-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .action-page {
    width: 100%;
    height: 100%;
  }
}
