.profile {
  position: relative;
  z-index: 1;
  text-align: left;
  display: flex;
  min-height: 100%;
  width: 100%;
  height: 100%;
  .profile-header {
    padding: 35px 0;
    // > h1 {
    //   // margin-top: 35px;
    // }
  }
  .form-section {
    width: 60%;
    height: 100%;
    padding: 35px;
    padding-bottom: 0;
    overflow-y: auto;
    .create-user {
      overflow: initial;
    }
    // background-color: green;
    > form {
      .inputs-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        height: 100%;
        // .input-label {
        //   .label {
        //     font-weight: bold;
        //     margin-bottom: 10px;
        //   }
        //   > input {
        //     width: 100%;
        //     border-radius: 50px;
        //     border: 1px solid #d5d5d5;
        //     padding: 10px 20px;
        //     outline: none;
        //   }
        // }
      }
      > button {
        margin-top: 35px;
      }
    }
  }
  .account-management {
    padding: 35px;

    width: 40%;
    // background-color: yellow;
    border-left: 1px solid #d5d5d5;
    overflow: auto;
    .links {
      > p {
        margin-bottom: 10px;
      }
      .link {
        color: #598b54;
        background: none;
        border: none;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .profile {
    flex-wrap: wrap;
    overflow-y: auto;
    .form-section {
      width: 100%;
      height: 100%;
      height: auto;
      .create-user {
        overflow: initial;
      }
    }
    .account-management {
      width: 100%;
    }
  }
}
