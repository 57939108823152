.create-user {
  width: 100%;
  height: 100%;
  padding: 2px;
  overflow: auto;

  // background-color: green;
  > form {
    .inputs-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
      height: 100%;
      // .input-label {
      //   .label {
      //     font-weight: bold;
      //     margin-bottom: 10px;
      //   }
      //   > input {
      //     width: 100%;
      //     border-radius: 50px;
      //     border: 1px solid #d5d5d5;
      //     padding: 10px 20px;
      //     outline: none;
      //   }
      // }
    }
    .search-address-input {
      .search-address {
        margin: 0;
        > label {
          font-weight: bold;
        }
        > .searchbar-addresses {
          > .search-bar {
            padding: 10px 20px;
            > span {
              display: none;
            }
          }
        }
      }
    }
    > button {
      margin-top: 35px;
    }
  }
}
.account-management {
  padding: 35px;

  width: 40%;
  // background-color: yellow;
  border-left: 1px solid #d5d5d5;
  .links {
    > p {
      margin-bottom: 10px;
    }
    .link {
      color: #598b54;
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }
}
