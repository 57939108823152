.bulletin-description {
  .image-section {
    > img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .content {
    h1 {
      text-align: center;
      margin: 20px auto;
    }
    > p {
      font-weight: 400;
    }
    > .info {
      margin-top: 40px;
    }
  }
}
