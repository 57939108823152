.table {
  display: grid;
  overflow-x: auto;
  flex-direction: column;
  border: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  z-index: 1;

  .fixed-column {
    background-color: inherit;
    position: sticky;
    left: 0;
    z-index: 1;
    // &:has(input[type="radio"]:checked) {
    //   background-color: rgba(89, 139, 84, 0.2);
    // }
  }

  .table-header {
    display: flex;
    background-color: #f2f2f2;
    font-weight: bold;
    cursor: pointer;
    &.selected-column {
      background-color: #dbdada;
      svg {
        margin-left: 5px;
      }
    }
  }

  .table-row {
    display: grid;
    background-color: white;
    &.active {
      // background-color: rgba(89, 139, 84, 0.2);
      background-color: rgb(207, 223, 206);
      // opacity: 0.5;
    }
  }
  .ad-row {
    background-color: #fffff0;
  }
  .table-column {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 150px;
    .not-visible {
      color: #cb5959;
    }
    p {
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .table-subcolumn {
      height: 100%;
      width: 100%;
      border-right: 1px solid #ddd;
      text-align: left;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: blue;
      // max-width: 150px;
      p {
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        border: none;
      }
    }
  }
}
