.layout {
  display: grid;
  color: black;
  // grid-template-rows: 0.8fr 3fr;
  grid-template-columns: 0.6fr 3fr;
  grid-template-areas: "side-bar page-content";
  // grid-template-areas: "side-bar page-content" "side-bar page-content";
  height: 100vh;
  position: relative;
  // overflow: hidden;
  // > .header {
  //   grid-area: header;
  //   background-color: yellow;
  // }
  .menu-button {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    width: 35px;
    height: 35px;
    z-index: 3;
    background-color: #598b54;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .side-bar {
    grid-area: side-bar;
    background-color: lightblue;
  }
  .page {
    grid-area: page-content;
    background-color: white;
    position: relative;
    height: 500px;
    // overflow-y: scroll;
    overflow-y: hidden;
    height: 100%;
    .page-container {
      display: grid;
      grid-template-rows: 0.8fr 3fr;
      height: 100%;
    }

    .page-content {
      margin-top: -200px;
      padding: 0 35px;
      padding-bottom: 35px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .scrolled {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 200px;
      z-index: 1;
      background: linear-gradient(
        180deg,
        #283736 0%,
        rgba(217, 217, 217, 0) 77.95%
      );
      opacity: 0;
      transition: opacity 0.5s ease;
      &.active {
        opacity: 0.1;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .survey-answers {
      flex-direction: column;
      row-gap: var(--space-sm);
    }
  }
}
@media screen and (max-width: 768px) {
  .layout {
    display: flex;
    flex-direction: column;
    .menu-button {
      display: flex;
    }
    .side-bar {
      display: none;
      &.show-sidebar{
        display: block;
        position: fixed;
        top: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
      }
    }
  }
}
