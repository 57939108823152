.bulletin {
  width: 100%;
  height: 100%;
  display: inherit;
  flex-direction: inherit;
  .bulletin-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .bulletin-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    height: 100%;
    .title-description {
      text-align: left;

      .title {
        font-size: 1.3em;
        margin-bottom: 10px;
      }
      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
    .details {
      display: flex;
      align-items: center;
      .info {
        text-align: left;
        margin-left: 10px;
        i {
          font-weight: 600;
        }
      }
    }
  }
}
