.bulletin-details {
  display: flex;
  align-items: center;
  .info {
    text-align: left;
    margin-left: 10px;
    i {
      font-weight: 600;
    }
  }
}
