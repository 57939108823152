.profile-picture {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
