.deed-restrictions {
  .deed-restrictions-container {
    position: relative;
    z-index: 1;
    text-align: left;
    .index-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .index {
        .index-list {
          white-space: nowrap;
          list-style: none;
          .index-item {
            color: #598b54;
            font-size: 1.3em;
            font-weight: 700;
            padding: 12px 0;
            border-bottom: 2px solid #d5d5d5;
            > a {
              color: #598b54;
              text-decoration: none;
              display: block;
              width: 100%;
              padding-right: 20px;
            }
          }
        }
      }
      .info {
        border: 2px solid #d5d5d5;
        padding: 50px;
        border-radius: 10px;
        max-width: 60%;
        font-weight: 400;
        font-size: 1em;
        .info-title {
          font-weight: 700;
          margin-bottom: 15px;
        }
      }
    }
    .content {
      margin-top: 6em;
      .content-item {
        margin-bottom: 50px;
        .content-item-title {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .content-item-paragraph {
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .deed-restrictions {
    .deed-restrictions-container {
      .index-info {
        // flex-direction: column;
        .info {
          max-width: 100%;
        }
      }
    }
  }
}
