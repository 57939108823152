.home {
  display: grid;
  // grid-template-columns: 3fr 1fr;
  height: 100vh;
  position: relative;

  .container {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/home-landscape.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // display: flex;

    .content-section {
      padding: 0 6.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      .content-map {
        position: absolute;
        top: 0;
        display: flex;
        padding: 0 6rem;
        z-index: 0;

        // background-color: red;
        width: 100%;
        gap: 50px;
        justify-content: space-between;
        right: 0;
        left: 0;
        bottom: 0;
        align-items: center;
        .info-wrapper {
          max-width: 500px;
          .label {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 2em;
          }
          h1 {
            font-size: 4em;
            font-family: "Abril Faface";
            line-height: 0.7;
          }
          .paragraph {
            // width: 65%;
            margin: 2em 0;
            font-size: 1em;
          }
        }
        .map {
          // width: 100%;
          height: 30em;
          // position: absolute;
          // right: calc(-500px / 2);
        }
      }
    }
  }
  > img {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    // height: auto;
    min-height: 100vh;
    display: flex;
    .container {
      .content-section {
        position: relative;
        height: 100%;
        padding: 30px 10px;
        justify-content: flex-start;
        .content-map {
          flex-wrap: wrap;
          position: initial;
          padding: 0;
          .map {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    > img {
      display: none;
    }
  }
}
