.admin-users {
  .action-buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    margin-bottom: 20px;
    .user-selected {
      position: relative;
      text-align: left;
      display: flex;
      gap: 15px;
      align-items: center;
      font-weight: bold;
      text-transform: capitalize;

      .edit {
        color: #598b54;
        margin-left: 5px;
        cursor: pointer;
      }

      .delete {
        color: #cb5959;
        cursor: pointer;

        > svg {
          margin-right: 5px;
        }

        &.confirm {
          padding: 10px;
          background-color: #cb5959;
          color: white;
          border-radius: 50px;
        }
      }
    }
    .download-csv {
      text-decoration: none;
      color: #598b54;
      background-color: white;
      border: 1px solid #c4c4c4;
      padding: 10px;
      border-radius: 50px;
      font-weight: bold;
      &:hover {
        opacity: 0.7;
      }
      > svg {
        margin-right: 5px;
      }
    }
  }
}
