.sidebar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px 35px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    url("../../assets/img/neighborhood-house.png");
  background-repeat: no-repeat;
  background-size: cover;
  .logo {
    margin-bottom: 50px;
  }
  .sidebar-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .sidebar-item {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.2em;
      color: black;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    nav {
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        text-align: left;
        li {
          margin: 10px 0;
          width: 100%;
          a {
            width: 100%;
            text-decoration: none;
            &.active {
              color: #598b54;
            }
          }
        }
      }
    }
    .admin-section {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      text-align: left;
      padding-top: 20px;
      > h1 {
        margin-bottom: 10px;
      }
    }
    .log-out {
      border: none;
      background-color: transparent;
      text-align: left;
      font-weight: bold;
      font-size: 1.2em;
      font-family: "Source Sans Pro", sans-serif;
      svg {
        margin-right: 5px;
      }
    }
  }
}
