.profile-input {
  .label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  > input {
    width: 100%;
    border-radius: 50px;
    border: 1px solid #d5d5d5;
    padding: 10px 20px;
    outline: none;
  }
  textarea {
    height: 110px;
    resize: none;
    outline: none;
    border-radius: 15px;
    padding: 15px;
    border: 1px solid #d5d5d5;
    font-family: inherit;
    font-size: inherit;
  }
  .custom-select {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    overflow: hidden;
    max-width: 150px;
    select {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border: none;
      cursor: pointer;
      outline: 0;
      appearance: 0;
    }
    svg {
      color: #598b54;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      z-index: 0;
    }
  }

  .error {
    color: #cb5959;
  }
}
