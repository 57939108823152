.account-not-found {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0) 25%
    ),
    url("../../assets/img/oldHouse2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content {
      margin: 0 auto;
      width: 550px;
      padding: 108px 43px;
      border-radius: 10px;
      background-color: rgba($color: #372828, $alpha: 0.9);
      h2 {
        font-size: 30px;
      }
      > p {
        text-align: left;
        margin: 20px 0;
        font-size: 20px;
      }
      .secondary-button {
        margin-top: 50px;
      }
    }
  }
}
