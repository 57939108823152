.user-post-list {
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 15px;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  padding: 5px;
  .user-post {
    flex: 0 0 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    > img {
      max-width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .bulletin-content {
      padding: 20px;
      .title {
        font-size: 1.5em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .action-buttons {
      position: relative;
      padding: 20px;
      padding-top: 0px;
      height: 100%;
      font-size: 1em;
      .edit {
        position: absolute;
        bottom: 20px;
        left: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        color: #598b54;
        svg {
          margin-left: 5px;
          font-size: 1.2em;
        }
      }
      .delete-section {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
        align-items: center;
      }
    }
    .delete {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      color: #cb5959;
      svg {
        margin-left: 5px;
      }
      &.confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #cb5959;
        color: white;
        border-radius: 50px;
        opacity: 1;
        // svg{
        //   margin: 0;
        // }
      }
      &.cancel {
        margin-right: 3px;
        font-size: 1em;
      }
    }
  }
  .confirm-deletion{
    background-color: rgba($color: #FFFFFF, $alpha: 0.9);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    gap:20px;
    .action-section{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    >h2{
      font-size: 1.3em;
    }
  }
}
