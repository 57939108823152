@mixin button($color, $background) {
  color: $color;
  background-color: $background;
  font-size: 1.2em;
  font-weight: 600;
  padding: 1em 3.5em;
  border: none;
  border-radius: 7em;
  cursor: pointer;

  text-transform: uppercase;
  margin: 0 auto;
  opacity: 1;
}
.primary-button {
  @include button(white, #372828);
}
.secondary-button {
  @include button(#283728, white);
}
