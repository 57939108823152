@font-face {
  font-family: "Source Sans Pro";
  src: local(""),
    url("../../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local(""),
    url("../../fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local(""),
    url("../../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf")
      format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local(""),
    url("../../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Abril Fatface";
  src: local(""),
    url("../../fonts/Abril_Fatface/AbrilFatface-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
