.user-header {
  position: relative;
  display: flex;
  padding: 35px;
  align-items: flex-end;
  justify-content: space-between;
  .title-action-button-container {
    display: flex;
    align-items: center;
    h1 {
      text-transform: capitalize;
      margin-right: 25px;
    }
    .action-button {
      background-color: transparent;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
      color: #598b54;
      font-weight: bold;
      font-size: 1em;
      // height: fit-content;
      border-radius: 1em;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      &.active {
        color: white;
        background-color: #598b54;
      }
      &:hover {
        opacity: 0.7;
      }
      svg {
        margin-right: 5px;
        font-size: 1.2em;
      }
    }
  }
  .search {
    position: relative;
    font-size: 1em;
    overflow: hidden;
    width: 20em;
    border-radius: 16em;
    border: 1px solid #372828;
    input {
      width: 100%;
      padding: 15px;
      padding-left: 20px;
      border: none;
      outline: 0;
      &::placeholder {
        color: black;
        opacity: 0.3;
      }
    }
    .search-icon {
      position: absolute;
      color: white;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background-color: #598b54;
      padding: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
@media screen and (max-width: 768px) {
  .user-header {
    flex-wrap: wrap;
  }
}
