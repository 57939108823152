.advertisement {
  width: 100%;
  height: 100%;
  display: inherit;
  flex-direction: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Source Sans Pro";

  .announcement-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px;
    height: 100%;

    .title-description {
      text-align: left;
      .ad {
        margin-bottom: 10px;
        font-weight: 600;
        .title {
          font-size: 1.3em;
        }
        .subtitle {
          font-weight: 600;
          // font-size: 1em;
        }
      }
      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-top: 20px;
      }
    }
    .info {
      font-weight: 600;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  &.with-image {
      background-position: center;
      .announcement-content {
      height: unset;
      min-height: 45%;
      width: 100%;
      background: #ffffff;
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      padding: 10px;
    }
  }
  .visit-button {
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 5px 15px;
    border-radius: 50px;
    color: #598b54;
    font-weight: 700;
    text-transform: uppercase;
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    svg {
      margin-left: 5px;
    }
  }
}
